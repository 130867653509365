export const listItemTexts = [
  "listItem1",
  "listItem2",
  "listItem3",
  "listItem4",
  "listItem5",
  "listItem6",
  "listItem7"
];
// export const listItemTexts = [
//   "Competitive Pricing",
//   "Bulk Discounts",
//   "Client Discounts",
//   "XML Connectivity",
//   "Fast Delivery",
//   "Expanding Offerings",
//   "Personalised Service",
//   "User Friendly",
// ];
export const paragraphs = [
  "paragraph1",
  "paragraph2",
  "paragraph3",
  "paragraph4",
];
// export const paragraphs = [
//   "Introducing our new and innovative B2B sales platform, designed to streamline your sourcing and buying process for consumer electronics.",
//   "Whether you're looking to purchase top-brand Apple or Samsung products, or seeking exclusive discounts and offers, we've got you covered.",
//   "Don't miss out on this opportunity to grow your business and take advantage of our exclusive offers.",
//   "Once you sign up we will provide you with access to browse our product catalogue, place orders, and track your deliveries. Our platform also allows you to integrate XML connections, extract price reports, and place drop-shipping deliveries.",
// ];
