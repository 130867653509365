export const navData = [
  {
    'refName': 'partner',
    'text': 'Home'
  },
  {
    'refName': 'about',
    'text': 'About us'
  },
  {
    'refName': 'b2b',
    'text': 'B2B'
  },
  {
    'refName': 'contacts',
    'text': 'Contacts'
  },
  {
    'refName': 'signup',
    'text': 'Sign up'
  },
]